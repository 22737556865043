<template>
  <div>
    <div>
      <indexHeader />

      <div
        class="content"
        @mouseenter="showIcon = true"
        @mouseleave="showIcon = false"
        style="position: relative"
        v-if="list.length"
      >
        <el-carousel
          indicator-position="none"
          @change="changeFun"
          class="content"
          ref="carouse"
          arrow="never"
          :autoplay="autoplaySwp"
          :initial-index="actIndex"
          @mouseenter.native="delHandleMouseEnter"
        >
          <el-carousel-item
            v-for="(item, index) in list"
            :key="index"
            class="content"
          >
            <img
              class="content"
              v-if="item.imagePc"
              @click="jumpTo(item)"
              :src="item.imagePc"
            />
            <video
              v-else
              class="content"
              muted
              preload
              :ref="`videoPlayer${index}`"
              @play="onPlay"
              @pause="onPause"
              :controls="false"
            >
              <source :src="item.videoPc" type="video/mp4" />
            </video>
          </el-carousel-item>
        </el-carousel>
        <div class="indicatorBox">
          <div
            class="indicatorBoxItem"
            v-for="(item, index) in list"
            :key="index"
            @click="changeIndicator(index)"
          >
            <div class="line" v-if="index != 0"></div>
            <div :class="actIndex == index ? 'yuanAct' : 'yuan'"></div>
          </div>
        </div>

        <div class="leftIcon" @click="leftIconClick" v-if="showIcon">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="rightIcon" @click="rightIconClick" v-if="showIcon">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>

      <div class="card2">
        <img class="hoverTransform" src="@/assets/images/c2.png" />
        <!-- <div class="card2Swiper">
          <el-carousel class="card2SwiperItem" indicator-position="none">
            <el-carousel-item
              class="card2SwiperItem"
              v-for="(item, index) in c2List"
              :key="index"
            >
              <div class="card2SwiperItem card2ItemBox">
                <div
                  class="card2Item hoverTransform"
                  v-for="(items, indexs) in item"
                  :key="indexs"
                >
                  <img class="card2ItemImg" :src="items.image" />
                  <div class="card2ItemText textOverflowOne">
                    {{ items.name }}
                  </div>
                  <div class="card2ItemLine"></div>
                  <div
                    class="card2ItemDeitails"
                    @click="toProductManualDetails(items)"
                  >
                    查看详情 <img src="@/assets/images/rightIcon.png" />
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div> -->
        <div class="card3Swiper card2Postiton">
          <el-carousel class="card3SwiperItem" indicator-position="none">
            <el-carousel-item
              class="card3SwiperItem"
              v-for="(item, index) in c2List"
              :key="index"
            >
              <div class="card3SwiperItem card3ItemBox">
                <div
                  class="card3Item hoverTransform"
                  v-for="(items, indexs) in item"
                  :key="indexs"
                  @click="toAchievementDetails(items)"
                >
                  <img class="card3ItemImg" :src="items.image" />
                  <div class="card3ItemText textOverflowOne">
                    {{ items.name }}
                  </div>
                  <div class="card3ItemLine"></div>
                  <div class="card3ItemDeitails">
                    查看详情 <img src="@/assets/images/rightIcon.png" />
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="tipBox">资料获取</div>
      </div>

      <div class="card2">
        <img class="hoverTransform" src="@/assets/images/c3.png" />
        <div class="card3Swiper">
          <el-carousel class="card3SwiperItem" indicator-position="none">
            <el-carousel-item
              class="card3SwiperItem"
              v-for="(item, index) in c3List"
              :key="index"
            >
              <div class="card3SwiperItem card3ItemBox">
                <div
                  class="card3Item hoverTransform"
                  v-for="(items, indexs) in item"
                  :key="indexs"
                  @click="toSchoolIndex(items)"
                >
                  <img class="card3ItemImg" :src="items.mainImage" />
                  <div class="card3ItemText textOverflowOne">
                    {{ items.name }}
                  </div>
                  <div class="card3ItemLine"></div>
                  <div class="card3ItemDeitails">
                    查看详情 <img src="@/assets/images/rightIcon.png" />
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="tipBox">培训学院</div>
      </div>

      <div class="contentImgBox" v-if="details && details.qas">
        <div class="contentImgBoxItem">
          <img class="hoverTransform" src="@/assets/images/b1.png" />
          <div class="b1MaskBox">
            <div class="b1Mask" style="padding: 0">
              <div class="swiper-container swiper-containerB1" id="swiperB1">
                <div class="swiper-wrapper swiper-wrapperB1">
                  <div
                    class="swiper-slide swiper-slideQuestion"
                    v-for="(item, index) in details.qas"
                    :key="index"
                    @click="forumDetailJump(item.id)"
                  >
                    {{ item.title }}
                  </div>
                </div>
              </div>
              <!-- <div class="b1MaskItem textOverflowTwo">
                {{ details.qas[0].title }}
              </div>
              <div
                class="b1MaskItem textOverflowTwo"
                v-if="details.qas.length > 1"
                @click="forumDetailJump(details.qas[1].id)"
              >
                {{ details.qas[1].title }}
              </div> -->
            </div>
          </div>
          <div class="tipBox">常见问题</div>
        </div>

        <div class="contentImgBoxItem">
          <img class="hoverTransform" src="@/assets/images/b2.png" />
          <div class="b2Mask">功能暂未上线，敬请期待</div>
          <div class="tipBox">积分商城</div>
        </div>

        <div class="contentImgBoxItem">
          <img class="hoverTransform" src="@/assets/images/b3.png" />
          <div class="b3MaskStop" @click="toCommunity"></div>
          <div class="b3Mask">
            <div class="swiper-container swiper-containerB3" id="swiperB3">
              <div class="swiper-wrapper swiper-wrapperB3">
                <div
                  class="swiper-slide swiper-slideB3"
                  v-for="(item, index) in details.factories"
                  :key="index"
                >
                  <div>
                    <img
                      class="b3ItemImg"
                      v-if="item.image"
                      :src="item.image"
                    />
                    <div v-else class="b3ItemeNoImg MyCustomFont">
                      <div>{{ item.abbr }}</div>
                    </div>
                    <div class="b3ItemImgText textOverflowOne">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tipBox">社区联盟</div>
        </div>
      </div>
    </div>

    <footers />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
import indexHeader from "@/components/indexHeader.vue";

import { Autoplay, EffectFade, Pagination, Navigation } from "swiper/modules";

import footers from "@/components/footer.vue";
import { centerView, banner } from "@/api/lx";
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";

export default {
  name: "Home",
  components: {
    indexHeader,
    footers,
  },
  data() {
    return {
      list: [],
      actIndex: 0,
      details: {},
      c2List: [],
      c3List: [],
      swiper: {},
      swiperB1: {},
      autoplaySwp: true,
      showIcon: false,
    };
  },
  mounted() {
    this.getCenterView();
    this.getSwiper();
  },
  methods: {
    onPlay() {
      // console.log("播放");
      this.autoplaySwp = false;
    },
    onPause() {
      // console.log("暂停");
      this.autoplaySwp = true;
    },
    delHandleMouseEnter() {
      this.$refs.carouse.handleMouseEnter = () => {};
    },
    forumDetailJump(id) {
      this.$router.push({
        path: "/forum/details",
        query: { id },
      });
    },
    b1Swiper() {
      this.swiperB1 = new Swiper("#swiperB1", {
        slidesPerView: "auto",
        direction: "vertical",
        loop: true, // 循环模式选项
        loopPreventsSlide: true, // 当swiper 正在过渡时，阻止slide 前进后退的切换操作
        observer: true,
        observeParents: true,
        speed: 2000, // slider自动滑动开始到结束的时间
        autoplay: true,
      });
    },
    b3Swiper() {
      this.swiper = new Swiper("#swiperB3", {
        slidesPerView: "auto",
        loop: true, // 循环模式选项
        loopPreventsSlide: true, // 当swiper 正在过渡时，阻止slide 前进后退的切换操作
        observer: true,
        observeParents: true,
        speed: 2000, // slider自动滑动开始到结束的时间
        autoplay: true,
      });
    },
    jumpTo(item) {
      if (item.url) {
        window.open(item.url, "_blank");
      }
    },

    getCenterView() {
      centerView().then((res) => {
        if (res.code == 200) {
          this.details = res.data;
          this.c2List = this.chunkArray(this.details.markets, 3);
          if (this.c2List.length < 3) {
            this.c2List = this.c2List.concat(this.c2List).concat(this.c2List);
          }
          this.c3List = this.chunkArray(this.details.videos, 3);
        }
        this.$nextTick(() => {
          this.b1Swiper();
          this.b3Swiper();
        });
      });
    },
    //数组切割成3个的
    chunkArray(arr, size) {
      const result = [];
      for (let i = 0; i < arr.length; i += size) {
        result.push(arr.slice(i, i + size));
      }
      return result;
    },
    getSwiper() {
      banner().then((res) => {
        if (res.code == 200) {
          this.list = res.data;
          this.changeFun(0);
        }
      });
    },

    tabs(e) {
      if (e == "remen") {
        this.$router.push({
          name: "beacon",
        });
      }
    },
    changeFun(e) {
      this.$nextTick(() => {
        this.list.forEach((item, index) => {
          if (item.videoPc) {
            this.$refs[`videoPlayer${index}`][0].pause();
          }
        });
        if (this.list[e].videoPc) {
          this.$refs[`videoPlayer${e}`][0].load();
          this.$refs[`videoPlayer${e}`][0].play();
        }
      });

      this.actIndex = e;
    },
    changeIndicator(e) {
      this.$refs.carouse.setActiveItem(e);
    },
    leftIconClick() {
      this.$refs.carouse.setActiveItem(this.actIndex - 1);
    },
    rightIconClick() {
      this.$refs.carouse.setActiveItem(this.actIndex + 1);
    },
    toSchoolIndex(item) {
      this.$router.push({
        path: "/school/details",
        query: { id: item.id },
      });
    },
    toAchievementDetails(item) {
      this.$router.push({
        path: "/information/achievementDetails",
        query: { id: item.id },
      });
    },
    toCommunity() {
      this.$router.push({
        path: "/community/index",
      });
    },
    // async getBaseInfo() {
    //   const res = await getBase();
    //   this.baseInfo = res.data.data;
    // },
  },
};
</script>

<style lang="less" scoped>
.content {
  //   background-image: url("../assets/img/3.png");
  height: 960px;
  background-size: 100% 100%;
  width: 100%;
  object-fit: fill;
}
.indicatorBox {
  position: absolute;
  left: 0;
  bottom: 51px;
  z-index: 900;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .indicatorBoxItem {
    display: flex;
    align-items: center;
    justify-content: center;

    .line {
      width: 20px;
      height: 1px;
      background: #ffffff;
      opacity: 0.4;
    }
    .yuanAct {
      width: 30px;
      height: 30px;
      background: #00d8c3;
      opacity: 0.6;
      border-radius: 50%;
      margin: 0 10px;
    }
    .yuan {
      width: 16px;
      height: 16px;
      background: #ffffff;
      opacity: 0.6;
      border-radius: 50%;
      margin: 0 10px;
    }
  }
}
.contentImgBox {
  width: 100%;
  height: 320px;
  display: flex;
  align-items: center;
  .contentImgL {
    width: 1280px;
    height: 100%;
    background-color: red;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .contentImgR {
    width: 640px;
    height: 100%;
    .contentImgRItem {
      width: 100%;
      height: 320px;
      background-color: red;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.tipBox {
  position: absolute;
  width: 100%;
  height: 60px;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 22px;
  color: #ffffff;
}
.contentImgBox {
  .contentImgBoxItem {
    width: 640px;
    height: 320px;
    position: relative;
    overflow: hidden;
    &:hover {
      .b1MaskBox {
        display: block;
      }
      .b2Mask {
        display: block;
      }
      .b3Mask {
        opacity: 1;
      }
    }
    img {
      width: 100%;
      height: 100%;
      // transition: transform 0.8s ease;
      // &:hover {
      //   transform: scale(1.1);
      // }
    }
  }
}
.card2 {
  width: 100%;
  height: 570px;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
  .card2Swiper {
    width: 1046px;
    height: 380px;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 65px;
    left: 50px;
    .card2SwiperItem {
      width: 1046px;
      height: 380px;
    }
    .card2ItemBox {
      display: flex;
      align-items: center;
      padding: 0 132px;
      box-sizing: border-box;
      .card2Item {
        width: 210px;
        height: 302px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 76px;
        .card2ItemImg {
          width: 210px;
          height: 210px;
          position: relative;
          z-index: 100;
        }
        .card2ItemText {
          width: 100%;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 18px;
          color: #ffffff;
          margin-top: 20px;
        }
        .card2ItemLine {
          width: 95px;
          height: 3px;
          background: #00d8c3;
          margin: 0 auto;
          margin-top: 8px;
        }
        .card2ItemDeitails {
          display: flex;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
          width: 94.5px;
          cursor: pointer;
          line-height: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 9px;
          img {
            width: 10px;
            height: 6px;
          }
        }
      }
    }
  }
}
.card3Swiper {
  width: 1046px;
  height: 380px;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 65px;
  right: 50px;
  .card3SwiperItem {
    width: 1046px;
    height: 380px;
    cursor: pointer;
  }
  .card3ItemBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 132px;
    box-sizing: border-box;
  }
  .card3Item {
    width: 210px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    .card3ItemImg {
      width: 210px;
      height: 119px;
    }
    .card3ItemText {
      width: 100%;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #ffffff;
      margin-top: 20px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
    }
    .card3ItemLine {
      width: 95px;
      height: 3px;
      background: #00d8c3;
      margin-top: 15px;
    }
    .card3ItemDeitails {
      width: 95px;
      display: flex;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      line-height: 30px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;
      margin-top: 6px;
      img {
        width: 10px;
        height: 6px;
      }
    }
  }
}
.b1MaskBox {
  .b1Mask {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 134px;
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    bottom: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    // font-weight: 600;
    font-size: 18px;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 0 55px;
    box-sizing: border-box;
    .b1MaskItem {
      width: 237px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #ffffff;
    }
  }
}
.b2Mask {
  position: absolute;
  width: 254px;
  height: 67px;
  background: rgba(0, 0, 0, 0.3);
  top: 108px;
  left: 213px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  justify-content: space-between;
  box-sizing: border-box;
  text-align: center;
  line-height: 67px;
  display: none;
}
.b3Mask {
  position: absolute;
  width: 100%;
  height: 67px;
  height: 204px;
  left: 0;
  bottom: 61px;
  z-index: 100;
  background: rgba(0, 0, 0, 0.3);
  opacity: 1;
}
.b3MaskStop {
  position: absolute;
  width: 100%;
  height: 67px;
  height: 204px;
  left: 0;
  bottom: 61px;
  z-index: 100;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  z-index: 10000;
}
.swiper-containerB3 {
  width: 100%;
  height: 150px;
  margin-top: 40px;
  padding-left: 50px;
  overflow: hidden;
}
.swiper-containerB1 {
  width: 100%;
  overflow: hidden;
}
.swiper-wrapper {
  width: 100%;
  transition-timing-function: linear !important;
  -webkit-transition-timing-function: linear !important;
  -moz-transition-timing-function: linear !important;
  -ms-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
.swiper-wrapperB1 {
  padding: 0 20px;
  box-sizing: border-box;
  height: 134px;
}
.swiper-wrapperB3 {
  height: 150px;
}
.swiper-slideB3 {
  width: 182px;
  height: 150px;
  margin: 0 20px;
}
.b3ItemImg {
  width: 96px !important;
  height: 96px !important;
  display: block !important;
  margin: 0 auto !important;
}
.b3ItemeNoImg {
  width: 96px !important;
  height: 96px !important;
  margin: 0 auto !important;
  color: #fff;
  font-size: 30px;
  background-color: #00d8c3;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    text-align: center;
  }
}
.b3ItemImgText {
  width: 100%;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  position: relative;
  margin-top: 10px;
  text-align: center;
}
.card2Postiton {
  position: absolute;
  top: 65px;
  left: 50px;
}

.swiper-slideQuestion {
  width: 100%;
  height: 55px;
}
.leftIcon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 400px;
  left: 100px;
  z-index: 10;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}
.rightIcon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 400px;
  right: 100px;
  z-index: 10;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}
</style>