<template>
  <div class="headers" id="indexHeader">
    <div class="containerBox">
      <div
        class="header flex align-items"
        style="justify-content: space-between"
      >
        <div class="left flex align-items"  style="cursor: pointer;">
          <img
            class="img"
            :src="
              scrollState
                ? require('../assets/img/sssssssss/logoBlack.png')
                : require('../assets/img/sssssssss/logoWhite.png')
            "
            alt=""
          />
        </div>
        <div class="right flex align-items">
          <nav class="nav" id="navItemTextColor">
            <div
              @mouseenter="handleMouseEnter(index)"
              class="nav-itemBox"
              @mouseleave="handleMouseLeave(index)"
              v-for="(item, index) in navList"
              :key="item.id"
            >
              <router-link
                :to="{ path: item.path !== '/information' ? item.path : '' }"
                class="nav-item"
                :class="{ active: scrollState && index === 0 }"
                :style="{
                  fontWeight: scrollState
                    ? 'normal'
                    : index === 0
                    ? 'bold'
                    : 'normal',
                }"
              >
                <div :style="{ color: scrollState ? '#00161A' : '#fff' }">
                  {{ item.name }}
                </div>
                <div
                  class="childNavMeun"
                  v-show="
                    item.children && item.children.length > 0 && item.show
                  "
                  :style="{
                    backgroundColor: !scrollState
                      ? 'rgba(0, 0, 0, 0.21)'
                      : '#fff',
                  }"
                >
                  <div
                    class="childNavMeunList"
                    v-for="(items, indexs) in item.children"
                    :key="items.id"
                  >
                    <router-link
                      v-if="index == 2"
                      :style="{
                        color: !scrollState ? '' : '#333 !important',
                      }"
                      class="childNavMeunLink"
                      :to="{ path: items.path }"
                      >{{ items.name }}</router-link
                    >
                    <router-link
                      v-else
                      :style="{
                        color: !scrollState ? '' : '#333 !important',
                      }"
                      class="childNavMeunLink"
                      :to="{ path: items.path }"
                      >{{ items.name }}</router-link
                    >
                  </div>
                </div>
              </router-link>
            </div>
          </nav>
          <login-menu :scrollState="scrollState" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loginMenu from "@/components/login-menu";
import { videoClassifyList } from "@/api/lx";
export default {
  components: {
    loginMenu,
  },
  data() {
    return {
      navList: [
        { id: 1, name: "首页", path: "/" },
        {
          id: 2,
          name: "资料获取",
          path: "/information",
          show: false,
          children: [
            { id: 9, name: "产品手册", path: "/information/productManual" },
            { id: 10, name: "解决方案", path: "/information/solution" },
            { id: 11, name: "市场业绩", path: "/information/achievement" },
            { id: 12, name: "软件及工具", path: "/information/tool" },
          ],
        },
        {
          id: 3,
          name: "培训学院",
          path: "/school/index",
          show: false,
          children: [],
        },
        { id: 4, name: "服务权益", path: "/service/index" },
        { id: 5, name: "社区联盟", path: "/community/index" },
        { id: 6, name: "常见问题", path: "/forum/index" },
        { id: 7, name: "积分商城", path: "/shop/index" },
      ],
      scrollState: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    this.getVideoClassifyList();
  },
  methods: {
    getVideoClassifyList() {
      videoClassifyList({}).then((res) => {
        res.data.map((ele) => {
          ele.path = "/school/index?id=" + ele.id;
        });
        this.navList[2].children = res.data;
      });
    },
    // 鼠标移入方法
    handleMouseEnter(index) {
      if (index === 1 || index === 2) {
        this.navList[1].show = false;
        this.navList[2].show = false;
        this.navList[index].show = true;
      }
    },
    handleMouseLeave(index) {
      this.navList[1].show = false;
      this.navList[2].show = false;
    },
    // 鼠标移出方法
    handleScroll() {
      // 当页面滚动到一定高度时显示返回顶部按钮
      const showButton = window.scrollY > 80; // 例如，滚动超过80px
      const indexHeaderStyle = document.getElementById("indexHeader");
      indexHeaderStyle.style.backgroundColor = showButton
        ? "#fff"
        : "rgba(0, 0, 0, 0.2)";
      this.scrollState = showButton;
    },
  },
};
</script>

<style scoped lang="less">
.containerBox{
  width: 1800px;
  margin: 0 auto;
}
.headers {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  .img {
    height: 36px;
    width: 330px;
  }
}
.nav {
  display: flex;
  align-items: center;
  margin-right: 50px;
  .nav-itemBox {
    padding: 0 25px;
    .nav-item {
      position: relative;
      text-align: center;
      display: block;
      color: #fff;
      cursor: pointer;
      text-decoration: none;
      position: relative;
      line-height: 74px;
      font-size: 18px;
      h3 {
        font-size: 16px;
      }
    }
    .active div {
      color: #00d8c3 !important;
    }
  }
}
.childNavMeun {
  position: absolute;
  top: 74px;
  left: -51px;
  background-color: rgba(0, 0, 0, 0.21);
  width: 175px;
  padding: 5px 0;
  z-index: 999;
  .childNavMeunList {
    line-height: 40px !important;
    .childNavMeunLink {
      cursor: pointer;
      font-size: 16px;
      color: #fff;
      text-decoration: none;
    }
  }
}

/deep/.header-custom-btn {
  color: #2e3033;
  border-color: transparent;
  background-color: transparent;
  a {
    color: initial;
    text-decoration: none;
  }
}

/deep/.user-info {
  .el-dropdown-link {
    color: #2e3033;
    cursor: pointer;
  }
}
</style>